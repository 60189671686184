import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import {
    LOGIN,
    DASHBOARD,
    BOOKING_SYSTEM_SETTINGS,
    CUSTOMERS,
    SALONS,
    SALONS_DETAIL,
    BOOKINGS,
    TREATMENTS,
    BOOKING_SYSTEM_INTEGRATION_VALEI,
    BOOKING_SYSTEM_INTEGRATION_VALEI_EDIT,
    EMAIL_TEMPLATES,
    EMAIL_TEMPLATE_EDIT,
    BOOKING_SYSTEM_INTEGRATION_EASY_EDIT,
} from "settings/constants";
import AuthProvider from "context/auth";
import { InLineLoader } from "components/InlineLoader/InlineLoader";
import PrivateRoute from "routes/PrivateRoutes";
import EmailTemplates from "views/Admin/EmailTemplates/Overview/EmailTemplates";
import EmailTemplateEdit from "views/Admin/EmailTemplates/EmailTemplateEdit/EmailTemplateEdit";
import EditSystemIntegrationEasyCashier from "views/Admin/BookingSystemIntegration/EasyCashier/EditSystemIntegrationEasyCashier";
const Layout = lazy(() => import("layouts/Layout"));
const Elastic = lazy(() => import("views/Admin/Elastic/Elastic"));
const Salons = lazy(() => import("views/Admin/Salons/Salons"));
const NotFound = lazy(() => import("views/NotFound/NotFound"));
const Login = lazy(() => import("views/Login/Login"));
const SalonDetail = lazy(() => import("views/Admin/SalonDetail/SalonDetail"));
const BookingSystemSettings = lazy(() => import("views/Admin/BookingSystemSettings/BookingSystemSettings"));
const Bookings = lazy(() => import("views/Admin/Bookings/Bookings"));
const Treatments = lazy(() => import("views/Admin/Treatments/Treatments"));
const Customers = lazy(() => import("views/Admin/Customers/Customers"));
const EditSystemIntegrationValei = lazy(() => import("views/Admin/BookingSystemIntegration/Valei/EditSystemIntegrationValei"));
const SystemIntegrationValei = lazy(() => import("views/Admin/BookingSystemIntegration/Valei/SystemIntegrationValei"));

const AdminRoutes = () => {
    return (
        <AuthProvider>
            <Suspense fallback={<InLineLoader />}>
                <Switch>
                    <PrivateRoute exact={true} path={DASHBOARD}>
                        <Layout>
                            <Suspense fallback={<InLineLoader />}>
                                <Elastic />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute path={SALONS}>
                        <Layout>
                            <Suspense fallback={<InLineLoader />}>
                                <Salons />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path={SALONS_DETAIL}>
                        <Layout>
                            <Suspense fallback={<InLineLoader />}>
                                <SalonDetail />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute path={CUSTOMERS}>
                        <Layout>
                            <Suspense fallback={<InLineLoader />}>
                                <Customers />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute path={BOOKING_SYSTEM_SETTINGS}>
                        <Layout>
                            <Suspense fallback={<InLineLoader />}>
                                <BookingSystemSettings />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute path={EMAIL_TEMPLATES}>
                        <Layout>
                            <Suspense fallback={<InLineLoader />}>
                                <EmailTemplates />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path={EMAIL_TEMPLATE_EDIT}>
                        <Layout>
                            <Suspense fallback={<InLineLoader />}>
                                <EmailTemplateEdit />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute path={BOOKINGS}>
                        <Layout>
                            <Suspense fallback={<InLineLoader />}>
                                <Bookings />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute path={TREATMENTS}>
                        <Layout>
                            <Suspense fallback={<InLineLoader />}>
                                <Treatments />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path={BOOKING_SYSTEM_INTEGRATION_VALEI}>
                        <Layout>
                            <Suspense fallback={<InLineLoader />}>
                                <SystemIntegrationValei />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute path={BOOKING_SYSTEM_INTEGRATION_VALEI_EDIT}>
                        <Layout>
                            <Suspense fallback={<InLineLoader />}>
                                <EditSystemIntegrationValei />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute path={BOOKING_SYSTEM_INTEGRATION_EASY_EDIT}>
                        <Layout>
                            <Suspense fallback={<InLineLoader />}>
                                <EditSystemIntegrationEasyCashier />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <Route path={LOGIN}>
                        <Login />
                    </Route>
                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </AuthProvider>
    );
};

export default AdminRoutes;
