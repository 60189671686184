import { styled } from "baseui";
import Images from "components/Image/Image";
import { themedStyled } from "theme";

export const EmailTemplateCardWrapper = themedStyled("div", ({ $theme }) => ({
    height: "100%",
    width: "100%",
    backgroundColor: "#ffffff",
    position: "relative",
    fontFamily: $theme.typography.primaryFontFamily,
    cursor: "pointer",
}));

export const SalonImageWrapper = themedStyled("div", ({ $theme }) => ({
    height: "240px",
    padding: "5px",
    position: "relative",
    borderBottom: `1px solid ${$theme.borders.borderE6}`,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "@media only screen and (max-width: 767px)": {
        height: "165px",
    },
}));

export const Image = styled(Images, () => ({
    maxWidth: "100%",
    maxHeight: "100%",
    display: "inline-block",
}));

export const ActiveTag = themedStyled("span", ({ $theme }) => ({
    ...$theme.typography.fontBold12,
    color: "#ffffff",
    // backgroundColor: $theme.colors.warning,
    padding: "0 10px",
    lineHeight: "2",
    borderRadius: "12px",
    display: "inline-block",
    position: "absolute",
    top: "15px",
    right: "15px",
}));

export const ActiveFlag = themedStyled("span", ({ $theme }) => ({
    ...$theme.typography.fontBold12,
    color: "#ffffff",
    lineHeight: "2",
    backgroundColor: $theme.colors.lightGreen,
    paddingLeft: "20px",
    paddingRight: "15px",
    display: "inline-block",
    position: "absolute",
    bottom: "10px",
    right: "0",

    ":before": {
        content: '""',
        position: "absolute",
        left: "-8px",
        top: "0",
        width: "0",
        height: "0",
        borderStyle: "solid",
        borderWidth: "0 8px 12px 0",
        borderColor: `transparent ${$theme.colors.lightGreen} transparent transparent`,
    },

    ":after": {
        content: '""',
        position: "absolute",
        left: "-8px",
        bottom: " 0",
        width: " 0",
        height: "0",
        borderStyle: "solid",
        borderWidth: "0 0 12px 8px",
        borderColor: `transparent transparent ${$theme.colors.lightGreen} transparent`,
    },
}));

export const SalonInfo = styled("div", ({ $theme }) => ({
    padding: "20px 25px 30px",

    "@media only screen and (max-width: 767px)": {
        padding: "15px 20px",
        // minHeight: '123px',
    },
}));

export const SalonTitle = themedStyled("h3", ({ $theme }) => ({
    ...$theme.typography.fontBold16,
    color: $theme.colors.textDark,
    margin: "0 0 7px 0",
    minHeight: "48px",

    "@media only screen and (max-width: 767px)": {
        ...$theme.typography.fontBold14,
        margin: "0 0 5px 0",
    },
}));

export const SalonMeta = styled("div", ({ $theme }) => ({
    marginTop: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "@media only screen and (max-width: 767px)": {
        // minHeight: '32px',
    },
}));

export const SalonID = themedStyled("span", ({ $theme }) => ({
    ...$theme.typography.fontBold14,
    color: $theme.colors.textDark,

    "@media only screen and (max-width: 767px)": {
        ...$theme.typography.fontBold12,
    },
}));

export const SalonPriceWrapper = styled("div", ({ $theme }) => ({
    display: "flex",
    alignItems: "center",
    position: "relative",
}));

export const SalonPrice = themedStyled("span", ({ $theme }) => ({
    ...$theme.typography.fontBold14,
    color: $theme.colors.primary,

    "@media only screen and (max-width: 767px)": {
        ...$theme.typography.fontBold12,
    },
}));

export const DiscountedPrice = themedStyled("span", ({ $theme }) => ({
    ...$theme.typography.font11,
    color: $theme.colors.textNormal,
    padding: "0 5px",
    position: "relative",
    overflow: "hidden",
    margin: "0 10px",

    ":before": {
        content: '""',
        width: "100%",
        height: "1px",
        display: "inline-block",
        backgroundColor: $theme.colors.textNormal,
        position: "absolute",
        top: "50%",
        left: "0",
    },
}));
