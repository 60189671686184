import { doDelete, doGet } from "./HttpRequestHandler";

const baseApiUrl = "api/v1/bookingsetting";

export const GetBookingSettings = (): any => doGet({ url: `${baseApiUrl}` });

export const GetBookingSettingById = (id): any => doGet({ url: `${baseApiUrl}/${id}` });

export const GetServicesFromSetting = (id): any => doGet({ url: `${baseApiUrl}/${id}/services` });

export const GetNativeServices = (): any => doGet({ url: `api/v1/services/alllowlevel` });

export const DeleteBookingSetting = (id): any => doDelete({ url: `api/v2/booking-system-setting/${id}` });
