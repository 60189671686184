import { SystemEmailTemplateDto } from "models/SystemEmailTemplate";
import { doGet, doPost, doPut } from "./HttpRequestHandler";
const baseApiUrl = "v3/util/system-email-template";

export const GetSystemEmailTemplate = (templateType, distributionChannelId): Promise<SystemEmailTemplateDto> =>
    doGet({ url: `${baseApiUrl}/${templateType}/${distributionChannelId}`, options: { useToast: false } });

export const SaveSystemEmailTemplate = (model): Promise<SystemEmailTemplateDto> => doPost({ url: `${baseApiUrl}`, model: model });

export const SendTestEmail = (model): Promise<boolean> => doPut({ url: `${baseApiUrl}/send-test-email`, model: model });
