import Checkbox from "components/CheckBox/CheckBox";
import DrawerBox from "components/DrawerBox/DrawerBox";
import Input from "components/Input/Input";
import { Button } from "baseui/button";
import { ChevronDown, ChevronRight } from "baseui/icon";
import { FormFields } from "components/FormFields/FormFields";
import { StyledTable, StyledHeadCell, StyledBodyCell } from "baseui/table-grid";
import { useState } from "react";
import { withStyle, useStyletron } from "baseui";

const CenteredBodyCell = withStyle(StyledBodyCell, {
    display: "flex",
    alignItems: "center",
});

const ResourceServiceRow = ({ rowIndex, services, valeiResources, setValeiResources }) => {
    const [css] = useStyletron();

    const handleRowChange = (serviceRowIndex, prop, value) => {
        const newArray = [...valeiResources];
        newArray[rowIndex].services[serviceRowIndex][prop] = value;
        setValeiResources(newArray);
    };

    return (
        <div
            className={css({
                gridColumn: "span 5",
                padding: "32px 24px",
            })}
        >
            <StyledTable role="grid" $gridTemplateColumns="max-content auto auto auto">
                <div role="row" className={css({ display: "contents" })}>
                    <StyledHeadCell $sticky={false}>Import</StyledHeadCell>
                    <StyledHeadCell $sticky={false}>Service</StyledHeadCell>
                    <StyledHeadCell $sticky={false}>Price</StyledHeadCell>
                    <StyledHeadCell $sticky={false}>From price</StyledHeadCell>
                </div>
                {services.map((service, i) => {
                    let isDisabled = service.id === null;
                    return (
                        <div role="row" key={`${service.externalId}-${i}`} className={css({ display: "contents" })}>
                            <StyledBodyCell>
                                <Checkbox
                                    disabled={isDisabled}
                                    checked={service.active}
                                    onChange={e => handleRowChange(i, "active", e.currentTarget.checked)}
                                />
                            </StyledBodyCell>
                            <StyledBodyCell>
                                {service.name}
                                {isDisabled && (
                                    <>
                                        <br />
                                        (NO MAPPING!)
                                    </>
                                )}
                            </StyledBodyCell>
                            <StyledBodyCell>
                                <FormFields>
                                    <Input
                                        disabled={isDisabled}
                                        type="text"
                                        value={service.price}
                                        onChange={e => handleRowChange(i, "price", e.currentTarget.value)}
                                    />
                                </FormFields>
                            </StyledBodyCell>
                            <StyledBodyCell>
                                <Checkbox
                                    disabled={isDisabled}
                                    checked={service.isFromPrice}
                                    onChange={e => handleRowChange(i, "isFromPrice", e.currentTarget.checked)}
                                />
                            </StyledBodyCell>
                        </div>
                    );
                })}
            </StyledTable>
        </div>
    );
};

const ResourceRow = ({ striped, row, index, valeiResources, setValeiResources }: any) => {
    const [css] = useStyletron();
    const [expanded, setExpanded] = useState(row.active);
    const handleRowChange = (prop, value) => {
        const newArray = [...valeiResources];
        newArray[index][prop] = value;
        setValeiResources(newArray);
    };

    return (
        <div role="row" className={css({ display: "contents" })}>
            <CenteredBodyCell $striped={striped}>
                <Checkbox checked={row.active} onChange={e => handleRowChange("active", e.currentTarget.checked)} />
            </CenteredBodyCell>
            <CenteredBodyCell $striped={striped}>
                <Button size="compact" kind="minimal" onClick={() => setExpanded(!expanded)} shape="square">
                    {expanded ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
                </Button>
                {row.name}
            </CenteredBodyCell>
            <CenteredBodyCell $striped={striped}>{row.services.filter(s => s.active).length}</CenteredBodyCell>
            <CenteredBodyCell $striped={striped}></CenteredBodyCell>
            <CenteredBodyCell $striped={striped}></CenteredBodyCell>
            {expanded && (
                <ResourceServiceRow
                    services={row.services}
                    valeiResources={valeiResources}
                    setValeiResources={setValeiResources}
                    rowIndex={index}
                />
            )}
        </div>
    );
};
interface IProps {
    valeiResources: any[];
    setValeiResources: Function;
    completeConfiguration: () => void;
}

const SystemResourceMapper = ({ valeiResources, setValeiResources, completeConfiguration }: IProps) => {
    const [css] = useStyletron();

    const handleSelectAll = e => {
        const newArray = [...valeiResources];
        newArray.forEach(element => {
            element.active = e.target.checked;
        });
        setValeiResources(newArray);
    };

    const hasAny = Boolean(valeiResources.length);
    const hasAll = hasAny && valeiResources.every(x => x.active);
    const hasSome = hasAny && valeiResources.some(x => x.active);
    const hasResources = valeiResources.length > 0;
    return (
        <DrawerBox>
            <div className={css({ width: "800px" })}>
                <StyledTable
                    role="grid"
                    $gridTemplateColumns="min-content minmax(300px, max-content) minmax(300px, max-content) max-content auto"
                >
                    <div role="row" className={css({ display: "contents" })}>
                        <StyledHeadCell>
                            <Checkbox checked={hasAll} isIndeterminate={!hasAll && hasSome} onChange={handleSelectAll} />
                        </StyledHeadCell>
                        <StyledHeadCell>Resource name</StyledHeadCell>
                        <StyledHeadCell>Selected services</StyledHeadCell>
                        <StyledHeadCell></StyledHeadCell>
                        <StyledHeadCell></StyledHeadCell>
                    </div>
                    {valeiResources.map((row, index) => {
                        const striped = index % 2 === 0;
                        return (
                            <ResourceRow
                                key={`${row.externalId}-${index}`}
                                row={row}
                                striped={striped}
                                index={index}
                                valeiResources={valeiResources}
                                setValeiResources={setValeiResources}
                            />
                        );
                    })}
                </StyledTable>
            </div>
            {hasResources && (
                <Button
                    onClick={completeConfiguration}
                    overrides={{
                        BaseButton: {
                            style: ({ $theme }) => ({
                                borderTopLeftRadius: "3px",
                                borderTopRightRadius: "3px",
                                borderBottomRightRadius: "3px",
                                borderBottomLeftRadius: "3px",
                                marginTop: "30px",
                            }),
                        },
                    }}
                >
                    Complete configuration
                </Button>
            )}
        </DrawerBox>
    );
};

export default SystemResourceMapper;
