import { EmailTemplateCardWrapper, SalonImageWrapper, Image, SalonTitle, SalonMeta, SalonID, SalonInfo } from "./EmailTemplateCard.style";
import { useHistory } from "react-router-dom";

type SalonCardProps = {
    selectedChannel: string;
    emailType: number;
    typeName: string;
};

const EmailTemplateCard = ({ selectedChannel, emailType, typeName }: SalonCardProps) => {
    let history = useHistory();
    return (
        <EmailTemplateCardWrapper
            onClick={() => {
                history.push(`/email-template/${selectedChannel}/${emailType}/edit`);
            }}
        >
            <SalonImageWrapper>
                <Image url={""} className="Salon-image" />
                {/* {salon.active ? (
          <>
            <ActiveTag></ActiveTag>
            <ActiveFlag>Active</ActiveFlag>
          </>
        ) : null} */}
            </SalonImageWrapper>
            <SalonInfo>
                <SalonTitle>{typeName}</SalonTitle>
                <SalonMeta>
                    <SalonID>{emailType}</SalonID>
                </SalonMeta>
            </SalonInfo>
        </EmailTemplateCardWrapper>
    );
};

export default EmailTemplateCard;
