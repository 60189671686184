import React, { useState } from "react";
import { Header, Heading } from "components/Wrapper.style";
import { Col, Grid, Row } from "components/FlexBox/FlexBox";
import Select from "components/Select/Select";
import { availableChannels } from "helpers/BookingSystemSettingHelper";
import Fade from "react-reveal/Fade";
import EmailTemplateCard from "./EmailTemplateCard";

const EmailTemplates = () => {
    const [channel, setChannel] = useState([{ name: "Rapide", id: "24057310-d2b1-44f9-8dd2-565c25332a4e" }]);
    const templateTypes = [
        { type: 0, name: "Welcome email" },
        { type: 1, name: "Reset password email" },
        { type: 2, name: "Booking confirmation email" },
        { type: 3, name: "Booking cancellation email" },
        { type: 4, name: "Booking reminder email" },
    ];

    const handleChannelChange = ({ value }) => {
        setChannel(value);
    };
    return (
        <Grid fluid={true}>
            <Row>
                <Col md={12}>
                    <Header
                        style={{
                            marginBottom: 30,
                            boxShadow: "0 0 5px rgba(0, 0 ,0, 0.05)",
                        }}
                    >
                        <Row>
                            <Col md={3}>
                                <Heading>Email templates</Heading>
                            </Col>
                            <Col md={9}>
                                <Row>
                                    <Col md={3}>
                                        <Select
                                            options={availableChannels}
                                            labelKey="name"
                                            valueKey="id"
                                            placeholder="Channel"
                                            value={channel}
                                            searchable={false}
                                            onChange={handleChannelChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Header>
                </Col>
            </Row>
            <Row>
                {templateTypes.map(t => (
                    <Col key={t.type} md={4} lg={3} sm={6} xs={12} style={{ margin: "15px 0" }}>
                        <Fade bottom duration={800} delay={10}>
                            <EmailTemplateCard emailType={t.type} selectedChannel={channel[0].id} typeName={t.name} />
                        </Fade>
                    </Col>
                ))}
            </Row>
        </Grid>
    );
};

export default EmailTemplates;
