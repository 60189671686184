import { toaster } from "baseui/toast";
import { AUTH_TOKEN_NAME } from "context/auth";
import { broadcastPublish } from "helpers/MessageBusHelper";
import { ApiServiceError, ProblemDetails } from "models/ApiErrorModel";
const apiHost = `//${window.location.host}/proxy`;

export const doGet = (requestModel: IBaseServiceRequest, file: boolean = false): Promise<any> => doAjax("GET", requestModel, file);

export const doPost = (requestModel: IBaseServiceRequest, file: boolean = false): Promise<any> => doAjax("POST", requestModel, file);

export const doPatch = (requestModel: IBaseServiceRequest): Promise<any> => doAjax("PATCH", requestModel);

export const doPut = (requestModel: IBaseServiceRequest): Promise<any> => doAjax("PUT", requestModel);

export const doDelete = (requestModel: IBaseServiceRequest): Promise<any> => doAjax("DELETE", requestModel);

export const doAjax = (verb: string, requestModel: IBaseServiceRequest, file: boolean = false): Promise<any> => {
    return new Promise((resolve, reject) => {
        const body = file ? requestModel.model : JSON.stringify(requestModel.model);
        let settings = {
            type: verb,
            url: `${apiHost}/${requestModel.url}`,
            data: body,
        };

        fetch(settings.url, {
            method: settings.type,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_NAME)}`,
            },
            body: settings.data,
        })
            .then(response => {
                if (response.ok) {
                    // if (downloadFile) {
                    //     const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    //     const fileName = decodeURIComponent(regex.exec(response.headers.get("Content-Disposition"))[1]);
                    //     return response.blob().then(blob => {
                    //         addFileToBodyAndTriggerDownload(fileName, blob);
                    //         return blob;
                    //     });
                    // }
                    return resolve(response.text().then(text => (text ? JSON.parse(text) : {})));
                }
                return reject(handleError(response, settings.url, body));
            })
            .catch(error => {
                if (error instanceof TypeError || error instanceof Error) {
                    const reason: ApiServiceError = {
                        handled: false,
                        error: {
                            detail: error.message,
                            status: 500,
                            extensions: {},
                            instance: "",
                            title: error.message,
                            traceId: "",
                            type: "",
                            url: "",
                        },
                    };
                    return reject(reason);
                }
                return reject(error);
            });
    });
};

const handleError = async (response: Response, url: string, body: string) => {
    const json = await response.text().then(text => (text ? JSON.parse(text) : {}));
    const error: ProblemDetails = { ...json, route: url };
    const reason: ApiServiceError = {
        handled: true,
        error,
    };
    const errorModel = json as ProblemDetails;
    errorModel && toaster.negative(<p> {errorModel.detail || errorModel.title}</p>, {});
    if (response.status === 401) broadcastPublish("auth.signout");
    return reason;
};
