import { BookingProviderType } from "models/BookingSettingModel";

export const getProviderName = (providerType: BookingProviderType): string => {
    switch (providerType) {
        case 1:
            return "Easy cashier";
        case 2:
            return "Its perfect";
        case 5:
            return "Valei";
        default:
            return "Service not found";
    }
};

export const getProviderPath = (providerType: BookingProviderType): string => {
    switch (providerType) {
        case 1:
            return "easy-cashier";
        case 2:
            return "its-perfect";
        case 5:
            return "valei";
        default:
            return "Service not found";
    }
};

export const getChannelName = (channel: string) => {
    switch (channel) {
        case "be5eb3fa-2bd7-49e2-aaad-7dd9a140f108":
            return "Book/Beauty";
        case "24057310-d2b1-44f9-8dd2-565c25332a4e":
            return "Rapide";
        case "fe813eeb-e49a-4836-8857-d3f1b97bbf4c":
            return "Rapide DK";
        case "7c22a05e-7894-4a52-ade7-08305d0bf814":
            return "Kicks SE";
        case "653fe48b-140f-468b-804c-5e5868c86061":
            return "Kicks FI";
        case "40de6838-0eb2-4cb0-b33a-5fc84c78044f":
            return "Kicks NO";
        case "f39bf62f-2009-4d30-a062-3554030458e1":
            return "Kicks Flagship";
        default:
            return "Service not found";
    }
};

export const availableChannels = [
    {
        id: "be5eb3fa-2bd7-49e2-aaad-7dd9a140f108",
        name: "Book/Beauty",
    },
    {
        id: "24057310-d2b1-44f9-8dd2-565c25332a4e",
        name: "Rapide",
    },
    {
        id: "fe813eeb-e49a-4836-8857-d3f1b97bbf4c",
        name: "Rapide DK",
    },
    {
        id: "7c22a05e-7894-4a52-ade7-08305d0bf814",
        name: "Kicks",
    },
    {
        id: "653fe48b-140f-468b-804c-5e5868c86061",
        name: "Kicks FI",
    },
    {
        id: "40de6838-0eb2-4cb0-b33a-5fc84c78044f",
        name: "Kicks NO",
    },
    {
        id: "f39bf62f-2009-4d30-a062-3554030458e1",
        name: "Kicks Flagship",
    },
];
