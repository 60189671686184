import React from "react";
import { Input as BaseInput, InputProps, SIZE } from "baseui/input";

const getInputFontStyle = ({ $theme }) => {
    return {
        color: $theme.colors.textDark,
        ...$theme.typography.fontBold16,
    };
};

const Input = ({ ...props }: InputProps) => {
    return (
        <BaseInput
            overrides={{
                Input: {
                    style: ({ $theme }) => {
                        return {
                            ...getInputFontStyle({ $theme }),
                        };
                    },
                },
            }}
            {...props}
        />
    );
};

export { SIZE };
export default Input;
