import React from "react";
import { Block, BlockProps } from "baseui/block";

const DrawerBox = ({ overrides, children }: BlockProps) => {
    return (
        <Block
            overrides={{
                Block: {
                    style: {
                        width: "100%",
                        height: "auto",
                        padding: "30px",
                        borderRadius: "3px",
                        backgroundColor: "#ffffff",
                    },
                },
                ...overrides,
            }}
        >
            {children}
        </Block>
    );
};

export default DrawerBox;
