import { styled } from "baseui";
import { themedStyled } from "theme";

export const Form = themedStyled("form", ({ $theme }) => ({
  // minHeight: '100vh',
  backgroundColor: $theme.colors.backgroundF7,
  paddingBottom: "100px"
}));

export const DrawerTitleWrapper = styled("div", ({ $theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "-55px 0 30px",
  position: "fixed"
}));

export const DrawerTitle = themedStyled("h3", ({ $theme }) => ({
  ...$theme.typography.fontBold18,
  margin: 0,
  color: $theme.colors.textDark
}));

export const FieldDetails = themedStyled("span", ({ $theme }) => ({
  ...$theme.typography.font14,
  padding: "28px 0 15px",
  color: $theme.colors.textNormal,
  display: "block",

  "@media only screen and (max-width: 991px)": {
    padding: "30px 0"
  }
}));

export const ButtonGroup = styled("div", ({ $theme }) => ({
  padding: "30px 60px",
  display: "flex",
  alignItems: "center",
  position: "sticky",
  bottom: "0",
  right: "0",
  width: "100%",
  backgroundColor: "#ffffff",
  boxShadow: "0 0 3px rgba(0, 0, 0, 0.1)",

  "@media only screen and (max-width: 767px)": {
    padding: "20px 30px"
  }
}));



export const CloseButton = themedStyled('button', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textNormal,
  lineHeight: 1.2,
  outline: '0',
  border: 'none',
  padding: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '10px',
  left: '-30px',
  right: 'auto',
  cursor: 'pointer',
  backgroundColor: '#ffffff',
  width: '20px',
  height: '20px',
  borderRadius: '50%',

  '@media only screen and (max-width: 767px)': {
    left: 'auto',
    right: '30px',
    top: '29px',
  },
}));