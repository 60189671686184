import { broadcastSubscribe } from "helpers/MessageBusHelper";
import { ApiAuthToken } from "models/ApiAuthToken";
import React, { useEffect } from "react";

type AuthProps = {
    isAuthenticated: boolean;
    authenticate: Function;
    signOut: Function;
};

export const AuthContext = React.createContext({} as AuthProps);

export const AUTH_TOKEN_NAME = "bb_admin_token";

const isValidToken = () => {
    const token = localStorage.getItem(AUTH_TOKEN_NAME);
    // JWT decode & check token validity & expiration.
    if (token) return true;
    return false;
};

const AuthProvider = (props: any) => {
    const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());
    useEffect(() => {
        broadcastSubscribe("auth.signout", signOut);
    }, []);
    const authenticate = async ({ username, password }) => {
        var details = {
            email: username,
            password: password,
        };
        const resp = await fetch(`/api/auth/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(details),
        });

        const authResp: ApiAuthToken = await resp.json();
        if (authResp != null) {
            makeAuthenticated(true);
            localStorage.setItem(AUTH_TOKEN_NAME, `${authResp.access_token}`);
        }
    };

    const signOut = cb => {
        makeAuthenticated(false);
        localStorage.removeItem(AUTH_TOKEN_NAME);
        setTimeout(cb, 100);
    };
    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                authenticate,
                signOut: signOut,
            }}
        >
            <>{props.children}</>
        </AuthContext.Provider>
    );
};

export default AuthProvider;
