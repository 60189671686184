import { doGet, doPost } from "./HttpRequestHandler";

const baseApiUrl = "api/v1/sync/settings/easycashier";

export const GetServices = (model: { integrationKey: string; authorizationKey: string; locationId: string }): any =>
    doPost({ url: `${baseApiUrl}/services`, model: model });

export const GetResourcesByServices = (model: any): any => doPost({ url: `${baseApiUrl}/resourcesByServices`, model: model });

export const SaveResources = (serviceCompanyName: string, mapping: any[]): any =>
    doPost({ url: `${baseApiUrl}/${serviceCompanyName}/saveResources}`, model: mapping });

export const CompleteConfiguration = (data: {
    integrationKey: string;
    services: any[];
    resources: any;
    organizationId: any;
    bookingSettingId: any;
}): any => doPost({ url: `${baseApiUrl}`, model: data });
