
export const broadcastSubscribe = (channel: string, callback: Function) => {
    const ch = new BroadcastChannel(channel);
    ch.onmessage = (ev: MessageEvent<any>) => callback(ev.data);
}

export const broadcastPublish = (channel: string, data?: any) => {
    const ch = new BroadcastChannel(channel);
    ch.postMessage(data || {})
}


export const events = {
    campaign: {
        info: {
            open: "campaign.info.open",
            updated: "campaign.info.updated"
        },
        mailsegment: {
            open: "campaign.mailsegment.open",
            updated: "campaign.mailsegment.updated"
        },
        servicesegment: {
            open: "campaign.servicesegment.open",
            updated: "campaign.servicesegment.updated"
        },
        timesegment: {
            open: "campaign.timesegment.open",
            updated: "campaign.timesegment.updated"
        },
        organizationsegment: {
            open: "campaign.organizationsegment.open",
            updated: "campaign.organizationsegment.updated"
        }
    },
    customer: {
        edit: "customer.edit"
    },
    salon: {
        created: "salon.created",
        create: "salon.create"
    },
    treatment: {
        edit: "treatment.edit",
        updated: "treatment.updated",
        create: "treatment.create",
        created: "treatment.created",
        deleted: "treatment.deleted",
        external: {
            deleted: "treatment.external.deleted",
            synced: "treatment.external.synced",
        }
    },
    booking: {
       detail:'booking.detail'
    }
};