// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const SALONS = '/salons';
export const SALONS_DETAIL = '/salon/:id';
export const TREATMENTS = '/treatments';
export const BOOKINGS = '/bookings';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const CUSTOMERS = '/customers';
export const BOOKING_SYSTEM_SETTINGS = '/booking-system-settings';
export const EMAIL_TEMPLATES = '/email-templates';
export const EMAIL_TEMPLATE_EDIT = '/email-template/:channelId/:templateType/edit';
export const BOOKING_SYSTEM_INTEGRATION_VALEI = '/booking-system-setting/valei';
export const BOOKING_SYSTEM_INTEGRATION_VALEI_EDIT = '/booking-system-setting/valei/:id';
export const BOOKING_SYSTEM_INTEGRATION_EASY_EDIT = '/booking-system-setting/easy-cashier/:id';
export const CAMPAIGNS = '/campaigns';

// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$';

