import * as Yup from "yup";
import Button from "components/Button/Button";
import DrawerBox from "components/DrawerBox/DrawerBox";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import { Form } from "components/DrawerItems/DrawerItems.style";
import { FormFields, FormLabel, Error } from "components/FormFields/FormFields";
import { GetSalons } from "services/SalonService";
import { Row, Col } from "components/FlexBox/FlexBox";
import { availableChannels } from "helpers/BookingSystemSettingHelper";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import Checkbox from "components/CheckBox/CheckBox";

export interface AuthenticationFormState {
    apiKey: string;
    subDomain: string;
    authKey: string;
    selectOrganzation: string;
    selectedChannels: string[];
    weeksToSync: number;
    canCancelOnline: boolean;
}

interface IProps {
    handleAuthSubmit: Function;
    authenticationSetting: AuthenticationFormState;
    isReadonly: boolean;
}
const SystemAuthentication = ({ handleAuthSubmit, authenticationSetting, isReadonly }: IProps) => {
    const [organizations, setOrganizations] = useState([]);
    const validationSchema = Yup.object({
        apiKey: Yup.string().required("Required"),
        subDomain: Yup.string().required("Required"),
        authKey: Yup.string().required("Required"),
        selectOrganzation: Yup.string().required("Required"),
        weeksToSync: Yup.number().required().min(0, "Must be greater than or equal to 0"),
        selectedChannels: Yup.array().of(Yup.string()).min(1, "Choose at least one"),
    });

    const handleSubmit = (values: AuthenticationFormState) => {
        handleAuthSubmit(values);
    };

    const formik = useFormik({
        initialValues: authenticationSetting,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        const getOrganizations = async () => {
            const result = await GetSalons();
            let newList = [];
            result.forEach(element => {
                newList.push({
                    value: element.id,
                    label: element.name,
                });
            });
            setOrganizations(newList);
        };
        getOrganizations();
    }, []);

    const handleOrgChange = ({ value }) => {
        formik.setFieldValue("selectOrganzation", value.length === 0 ? "" : value[0].value);
    };

    const handleChannelsChange = ({ value }) => {
        const selectedValues = value.map(v => v.id);
        formik.setFieldValue("selectedChannels", selectedValues);
    };

    const standardFontBold = $theme => ({
        ...$theme.typography.fontBold14,
        color: $theme.colors.textNormal,
    });

    return (
        <Form onSubmit={formik.handleSubmit} style={{ height: "100%" }}>
            <DrawerBox>
                <Row>
                    <Col md={4}>
                        <FormFields>
                            <FormLabel>SubDomain</FormLabel>
                            <Input
                                disabled={isReadonly}
                                id="subDomain"
                                name="subDomain"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.subDomain}
                            />
                            {formik.touched.subDomain && formik.errors.subDomain && <Error>{formik.errors.subDomain}</Error>}
                        </FormFields>
                    </Col>
                    <Col md={4}>
                        <FormFields>
                            <FormLabel>ApiKey</FormLabel>
                            <Input
                                disabled={isReadonly}
                                id="apiKey"
                                name="apiKey"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.apiKey}
                            />
                            {formik.touched.apiKey && formik.errors.apiKey && <Error>{formik.errors.apiKey}</Error>}
                        </FormFields>
                    </Col>

                    <Col md={4}>
                        <FormFields>
                            <FormLabel>Authorization Key</FormLabel>
                            <Input
                                disabled={isReadonly}
                                id="authKey"
                                name="authKey"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.authKey}
                            />
                            {formik.touched.authKey && formik.errors.authKey && <Error>{formik.errors.authKey}</Error>}
                        </FormFields>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormFields>
                            <FormLabel>Organization</FormLabel>
                            <Select
                                disabled={isReadonly}
                                options={organizations}
                                labelKey="label"
                                valueKey="value"
                                placeholder="Choose organization"
                                searchable
                                name="selectOrganzation"
                                onChange={handleOrgChange}
                                overrides={{
                                    Placeholder: {
                                        style: ({ $theme }) => standardFontBold($theme),
                                    },
                                    DropdownListItem: {
                                        style: ({ $theme }) => standardFontBold($theme),
                                    },
                                    OptionContent: {
                                        style: ({ $theme, $selected }) => {
                                            return {
                                                ...$theme.typography.fontBold14,
                                                color: $selected ? $theme.colors.textDark : $theme.colors.textNormal,
                                            };
                                        },
                                    },
                                    SingleValue: {
                                        style: ({ $theme }) => standardFontBold($theme),
                                    },
                                    Popover: {
                                        props: {
                                            overrides: {
                                                Body: {
                                                    style: { zIndex: 5 },
                                                },
                                            },
                                        },
                                    },
                                }}
                                value={organizations.filter(option => option.value === formik.values.selectOrganzation)}
                            />
                            {formik.touched.selectOrganzation && formik.errors.selectOrganzation && (
                                <Error>{formik.errors.selectOrganzation}</Error>
                            )}
                        </FormFields>
                    </Col>
                    <Col md={4}>
                        <FormFields>
                            <FormLabel>Channels</FormLabel>
                            <Select
                                disabled={isReadonly}
                                options={availableChannels}
                                labelKey="name"
                                valueKey="id"
                                placeholder="Channels"
                                value={availableChannels.filter(option => formik.values.selectedChannels.includes(option.id))}
                                onChange={handleChannelsChange}
                                overrides={{
                                    Placeholder: {
                                        style: ({ $theme }) => standardFontBold($theme),
                                    },
                                    DropdownListItem: {
                                        style: ({ $theme }) => standardFontBold($theme),
                                    },
                                    Popover: {
                                        props: {
                                            overrides: {
                                                Body: {
                                                    style: { zIndex: 5 },
                                                },
                                            },
                                        },
                                    },
                                }}
                                multi
                            />

                            {formik.touched.selectedChannels && formik.errors.selectedChannels && (
                                <Error>{formik.errors.selectedChannels}</Error>
                            )}
                        </FormFields>
                    </Col>
                    <Col md={2}>
                        <FormFields>
                            <FormLabel>Weeks to sync</FormLabel>
                            <Input
                                disabled={isReadonly}
                                id="weeksToSync"
                                name="weeksToSync"
                                type="number"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.weeksToSync}
                            />
                            {formik.touched.weeksToSync && formik.errors.weeksToSync && <Error>{formik.errors.weeksToSync}</Error>}
                        </FormFields>
                    </Col>
                    <Col md={2}>
                        <FormFields>
                            <FormLabel>Cancel online</FormLabel>
                            <Checkbox
                                disabled={isReadonly}
                                name="canCancelOnline"
                                checked={formik.values.canCancelOnline}
                                onChange={formik.handleChange}
                            />
                        </FormFields>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        {!isReadonly && (
                            <Button
                                type="submit"
                                overrides={{
                                    BaseButton: {
                                        style: () => ({
                                            borderTopLeftRadius: "3px",
                                            borderTopRightRadius: "3px",
                                            borderBottomRightRadius: "3px",
                                            borderBottomLeftRadius: "3px",
                                            marginTop: "30px",
                                        }),
                                    },
                                }}
                            >
                                Test connection
                            </Button>
                        )}
                    </Col>
                </Row>
            </DrawerBox>
        </Form>
    );
};

export default SystemAuthentication;
