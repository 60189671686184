import SystemAuthentication, { AuthenticationFormState } from "../components/SystemAuthentication";
import SystemResourceMapper from "../components/SystemResourceMapper";
import SystemTreatmentMapper from "../components/SystemTreatmentMapper";
import { BOOKING_SYSTEM_SETTINGS } from "settings/constants";
import { CompleteConfiguration, GetResourcesByServices, GetServices } from "services/EasyCashierService";
import { GetBookingSettingById, GetNativeServices, GetServicesFromSetting } from "services/BookingSystemSettingsService";
import { ProgressSteps, NumberedStep } from "baseui/progress-steps";
import { ServiceModel } from "models/ServiceModel";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { SpacedButton } from "../Valei/SystemIntegrationValei.style";
import { Spinner } from "baseui/spinner";

type EditSystemIntegrationEasyCashierParam = {
    id: string;
};
const EditSystemIntegrationEasyCashier = () => {
    let { id } = useParams<EditSystemIntegrationEasyCashierParam>();
    let history = useHistory();
    const [current, setCurrent] = useState(0);
    const [authenticationSetting, setAuthenticationSetting] = useState<AuthenticationFormState>({
        apiKey: "",
        subDomain: "",
        authKey: "",
        selectOrganzation: "",
        selectedChannels: [],
        weeksToSync: 6,
        canCancelOnline: false,
    });

    const [easyCashierServices, setValeiServices] = useState([]);
    const [easyCashierResources, setValeiResources] = useState([]);
    const [bobServices, setBobServices] = useState<ServiceModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const [setting, existingServices, nativeServices] = await Promise.all([
            GetBookingSettingById(id),
            GetServicesFromSetting(id),
            GetNativeServices(),
        ]);

        var model = {
            integrationKey: setting.integrationKey,
            locationId: setting.locationId,
            authorizationKey: setting.authorizationKey,
            bookingSettingId: id,
            serviceMaps: existingServices,
        };
        const result = await GetResourcesByServices(model);

        const newAuth = { ...authenticationSetting };
        newAuth.apiKey = setting.integrationKey;
        newAuth.selectOrganzation = setting.orgId;
        newAuth.authKey = setting.authorizationKey;
        newAuth.subDomain = setting.locationId;
        newAuth.selectedChannels = setting.channels;
        newAuth.canCancelOnline = setting.canCancelOnline;
        newAuth.weeksToSync = setting.weeksToSync;

        existingServices.forEach(element => {
            element.uuid = uuidv4();
        });

        setAuthenticationSetting(newAuth);
        setBobServices(nativeServices);
        setValeiServices(existingServices);

        setValeiResources(result);
        setCurrent(2);

        setIsLoading(false);
    };

    const getResourcesByServices = async () => {
        setIsLoading(true);
        const mapping = easyCashierServices.filter(currentService => currentService.nativeServiceId);

        var model = {
            integrationKey: authenticationSetting.apiKey,
            locationId: authenticationSetting.subDomain,
            authorizationKey: authenticationSetting.authKey,
            bookingSettingId: id,
            serviceMaps: mapping,
        };
        const result = await GetResourcesByServices(model);
        setValeiResources(result);
        setCurrent(2);

        setIsLoading(false);
    };

    const handleAuthSubmit = async authSettings => {
        setIsLoading(true);
        const res = await GetServices({
            authorizationKey: authSettings.authKey,
            integrationKey: authSettings.apiKey,
            locationId: authSettings.subDomain,
        });
        res.forEach(element => {
            element.uuid = uuidv4();
        });
        setValeiServices(res);

        setAuthenticationSetting(authSettings);
        setCurrent(1);
        setIsLoading(true);
    };

    const completeConfiguration = async () => {
        const resourceMapping = easyCashierResources.filter(item => item.active);
        const serviceMapping = easyCashierServices.filter(currentService => currentService.nativeServiceId);

        var model = {
            integrationKey: authenticationSetting.apiKey,
            locationId: authenticationSetting.subDomain,
            authorizationKey: authenticationSetting.authKey,
            weeksToSync: authenticationSetting.weeksToSync,
            canCancelOnline: authenticationSetting.canCancelOnline,
            services: serviceMapping,
            resources: resourceMapping,
            organizationId: authenticationSetting.selectOrganzation,
            channels: authenticationSetting.selectedChannels,
            bookingSettingId: id,
        };
        const result = await CompleteConfiguration(model);

        history.push(BOOKING_SYSTEM_SETTINGS);
    };

    return (
        <ProgressSteps current={current}>
            <NumberedStep title="Connect to Easy Cashier">
                {isLoading ? (
                    <Spinner size={64} />
                ) : (
                    <>
                        <SystemAuthentication
                            handleAuthSubmit={handleAuthSubmit}
                            authenticationSetting={authenticationSetting}
                            isReadonly={true}
                        />
                        <SpacedButton disabled>Previous</SpacedButton>
                        <SpacedButton onClick={() => setCurrent(1)}>Next</SpacedButton>
                    </>
                )}
            </NumberedStep>
            <NumberedStep title="Map treatments">
                {isLoading ? (
                    <Spinner size={64} />
                ) : (
                    <>
                        <SystemTreatmentMapper
                            bobServices={bobServices}
                            getResourcesByServices={getResourcesByServices}
                            valeiServices={easyCashierServices}
                            setValeiServices={setValeiServices}
                        />
                        <SpacedButton onClick={() => setCurrent(0)}>Previous</SpacedButton>
                        <SpacedButton onClick={() => setCurrent(2)}>Next</SpacedButton>
                    </>
                )}
            </NumberedStep>
            <NumberedStep title="Map resources">
                {isLoading ? (
                    <Spinner size={64} />
                ) : (
                    <>
                        <SystemResourceMapper
                            valeiResources={easyCashierResources}
                            setValeiResources={setValeiResources}
                            completeConfiguration={completeConfiguration}
                        />
                        <SpacedButton onClick={() => setCurrent(1)}>Previous</SpacedButton>
                        <SpacedButton disabled>Next</SpacedButton>
                    </>
                )}
            </NumberedStep>
        </ProgressSteps>
    );
};

export default EditSystemIntegrationEasyCashier;
