import DrawerBox from "components/DrawerBox/DrawerBox";
import EmailEditor from "react-email-editor";
import Input from "components/Input/Input";
import { Col, Row } from "components/FlexBox/FlexBox";
import { FormFields, FormLabel } from "components/FormFields/FormFields";
import { GetSystemEmailTemplate, SaveSystemEmailTemplate, SendTestEmail } from "services/SystemEmailTemplateService";
import { SystemEmailTemplateDto } from "models/SystemEmailTemplate";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, KIND } from "baseui/button";
import { toaster } from "baseui/toast";
import { EmailTypeSetting } from "../models/EmailTemplatesModels";
import { AllEmailTypeSettings } from "../helpers/EmailTemplateHelper";
import { Textarea } from "components/Textarea/Textarea";

type EmailTemplateEditParam = {
    channelId: string;
    templateType: string;
};
const EmailTemplateEdit = () => {
    const { channelId, templateType } = useParams<EmailTemplateEditParam>();

    const [state, setState] = useState<SystemEmailTemplateDto>({
        subject: "",
    });

    const [currentTypeSetting, setCurrentTypeSetting] = useState<EmailTypeSetting>({
        hint: "",
        mergeTags: [],
    });

    const [testEmailUser, setTestEmailUser] = useState("");
    const emailEditorRef = useRef<EmailEditor>(null);

    const saveTemplateClick = async () => {
        emailEditorRef.current.exportHtml(data => {
            const { design, html } = data;
            const req = {
                templateDesignJson: JSON.stringify(design),
                templateHtml: html,
            };
            saveTemplate(req);
        });
    };

    const sendTestEmailClick = async () => {
        if (!testEmailUser) {
            toaster.negative(`Empty email.`, { autoHideDuration: 5000 });
            return;
        }
        emailEditorRef.current.exportHtml(data => {
            const { html } = data;
            const req = {
                bodyHtml: html,
            };
            sendTestEmail(req);
        });
    };

    const sendTestEmail = async req => {
        const testReq = {
            subject: state.subject,
            toEmail: testEmailUser,
            htmlBody: req.bodyHtml,
            channelId: channelId,
        };
        const res = await SendTestEmail(testReq);
        if (res) toaster.positive(`Test email sent.`, { autoHideDuration: 5000 });
    };

    const saveTemplate = async req => {
        const saveReq = {
            ...state,
            ...req,
            emailType: parseInt(templateType),
            distributionChannelId: channelId,
        };
        const res = await SaveSystemEmailTemplate(saveReq);
        if (res) toaster.positive(`Email templated updated.`, { autoHideDuration: 5000 });
        // await TestSend(req)
    };

    const onLoad = async () => {
        try {
            const r = await GetSystemEmailTemplate(templateType, channelId);
            setState(r);
            emailEditorRef.current.loadDesign(JSON.parse(r.templateDesignJson));
        } catch (error) {}

        const currentSetting = AllEmailTypeSettings.find(s => s.emailType == templateType);

        if (currentSetting) {
            emailEditorRef.current.setMergeTags(currentSetting.setting.mergeTags);
            setCurrentTypeSetting(currentSetting.setting);
        }
    };

    const handleSubjectChange = e => {
        setState({
            ...state,
            subject: e.currentTarget.value,
        });
    };
    return (
        <div>
            <DrawerBox>
                <Row>
                    <Col md={4}>
                        <FormFields>
                            <FormLabel>Subject</FormLabel>
                            <Input onChange={handleSubjectChange} value={state.subject} />
                        </FormFields>
                    </Col>
                    <Col md={4}>
                        <Button
                            onClick={saveTemplateClick}
                            overrides={{
                                BaseButton: {
                                    style: ({ $theme }) => ({
                                        width: "30%",
                                        borderTopLeftRadius: "3px",
                                        borderTopRightRadius: "3px",
                                        borderBottomRightRadius: "3px",
                                        borderBottomLeftRadius: "3px",
                                        marginTop: "30px",
                                        marginRight: "15px",
                                        marginLeft: "auto",
                                    }),
                                },
                            }}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormFields>
                            <FormLabel>Test send email</FormLabel>
                            <Input
                                placeholder="Enter email."
                                value={testEmailUser}
                                onChange={e => setTestEmailUser(e.currentTarget.value)}
                            />
                        </FormFields>
                    </Col>
                    <Col md={4}>
                        <Button
                            kind={KIND.secondary}
                            onClick={sendTestEmailClick}
                            overrides={{
                                BaseButton: {
                                    style: ({ $theme }) => ({
                                        width: "30%",
                                        borderTopLeftRadius: "3px",
                                        borderTopRightRadius: "3px",
                                        borderBottomRightRadius: "3px",
                                        borderBottomLeftRadius: "3px",
                                        marginTop: "30px",
                                        marginLeft: "auto",
                                    }),
                                },
                            }}
                        >
                            Send
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormFields>
                            <FormLabel>Hint</FormLabel>
                            <Textarea name="description" disabled value={currentTypeSetting.hint} />
                        </FormFields>
                    </Col>
                </Row>
            </DrawerBox>
            <EmailEditor ref={emailEditorRef} onLoad={onLoad} minHeight={800} />
        </div>
    );
};

export default EmailTemplateEdit;
