import { AddressModel } from "models/AddressModel";
import { doGet, doPost, doPut } from "./HttpRequestHandler";

const baseApiUrl = "api/v1/salon";

export const GetSalons = (): any => doGet({ url: `${baseApiUrl}` });
export const GetSalon = (id: string): any => doGet({ url: `${baseApiUrl}/db/${id}` });
export const GetSalonServices = (id: string): any => doGet({ url: `api/v2/salon/${id}/services` });
export const GetSalonResources = (id: string): any => doGet({ url: `${baseApiUrl}/${id}/resources` });
export const GetSalonAddress = (id: string): Promise<AddressModel> => doGet({ url: `${baseApiUrl}/${id}/address` });
export const CreateSalon = (model: any): any => doPost({ url: `${baseApiUrl}`, model: model });
export const SaveSalonInfo = (id: string, model: any): any => doPut({ url: `${baseApiUrl}/${id}/info`, model: model });
export const SaveSalonAddress = (id: string, model: any): any => doPost({ url: `${baseApiUrl}/${id}/address`, model: model });
