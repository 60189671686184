import DrawerBox from "components/DrawerBox/DrawerBox";
import Input from "components/Input/Input";
import { ArrowRight, Plus } from "baseui/icon";
import { FormFields } from "components/FormFields/FormFields";
import { Select, TYPE } from "baseui/select";
import { ServiceModel } from "models/ServiceModel";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { v4 as uuidv4 } from "uuid";
import { Button, SIZE, KIND } from "baseui/button";

interface IProps {
    valeiServices: any[];
    bobServices: ServiceModel[];
    setValeiServices: Function;
    getResourcesByServices: (e) => void;
}
const SystemTreatmentMapper = ({ valeiServices, bobServices, setValeiServices, getResourcesByServices }: IProps) => {
    const handleRowChange = (rowIndex, value, prop) => {
        const newServices = [...valeiServices];
        newServices[rowIndex][prop] = value;
        setValeiServices(newServices);
    };

    const duplicateRow = (index, row) => {
        const newServices = [...valeiServices];
        const newRow = { ...row };
        newRow.uuid = uuidv4();
        newServices.splice(index, 0, newRow);
        setValeiServices(newServices);
    };
    const hasServices = valeiServices.length > 0;

    return (
        <DrawerBox>
            <TableBuilder data={valeiServices}>
                <TableBuilderColumn header="Valei treatment">
                    {row => (
                        <>
                            {row.serviceName} {row.price} (kr){" "}
                        </>
                    )}
                </TableBuilderColumn>
                <TableBuilderColumn header="To">{row => <ArrowRight />}</TableBuilderColumn>
                <TableBuilderColumn header="Book beauty treatment">
                    {(row, i) => (
                        <Select
                            options={bobServices}
                            labelKey="displayName"
                            valueKey="id"
                            placeholder="Choose a treatment"
                            maxDropdownHeight="300px"
                            overrides={{
                                Input: {
                                    style: ({ $theme }) => {
                                        return {
                                            width: "200px",
                                        };
                                    },
                                },
                            }}
                            type={TYPE.search}
                            onChange={({ value }) => handleRowChange(i, value.length === 0 ? "" : value[0].id, "nativeServiceId")}
                            value={bobServices.filter(option => option.entityId === row.nativeServiceId)}
                        />
                    )}
                </TableBuilderColumn>
                <TableBuilderColumn header="From price">
                    {(row, i) => (
                        <FormFields>
                            <Input type="text" value={row.price} onChange={e => handleRowChange(i, e.currentTarget.value, "price")} />
                        </FormFields>
                    )}
                </TableBuilderColumn>
                <TableBuilderColumn header=" ">
                    {(row, i) => (
                        <Button onClick={() => duplicateRow(i, row)} size={SIZE.compact} kind={KIND.minimal}>
                            <Plus title="Copy" />
                        </Button>
                    )}
                </TableBuilderColumn>
            </TableBuilder>
            {hasServices && (
                <Button
                    onClick={getResourcesByServices}
                    overrides={{
                        BaseButton: {
                            style: ({ $theme }) => ({
                                borderTopLeftRadius: "3px",
                                borderTopRightRadius: "3px",
                                borderBottomRightRadius: "3px",
                                borderBottomLeftRadius: "3px",
                                marginTop: "30px",
                            }),
                        },
                    }}
                >
                    Save and continue
                </Button>
            )}
        </DrawerBox>
    );
};

export default SystemTreatmentMapper;
