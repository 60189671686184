import { EmailTypeSettings } from "../models/EmailTemplatesModels";

export const AllEmailTypeSettings: EmailTypeSettings[] = [
    {
        emailType: "0",
        setting: {
            hint: "Hint text.",
            mergeTags: [
                {
                    name: "First Name",
                    value: "{{first_name}}",
                    sample: "John",
                },
                {
                    name: "Last Name",
                    value: "{{last_name}}",
                    sample: "Doe",
                },
            ],
        },
    },
    {
        emailType: "1",
        setting: {
            hint: "The tag {{reset_password_url}} is mandatory.",
            mergeTags: [
                {
                    name: "First Name",
                    value: "{{first_name}}",
                    sample: "John",
                },
                {
                    name: "Last Name",
                    value: "{{last_name}}",
                    sample: "Doe",
                },
                {
                    name: "Reset password url",
                    value: "{{reset_password_url}}",
                    sample: "https://google.se",
                },
            ],
        },
    },
    {
        emailType: "2",
        setting: {
            hint: "Don't forget the tag {{cancel_booking_url}}.",
            mergeTags: [
                {
                    name: "First Name",
                    value: "{{first_name}}",
                    sample: "John",
                },
                {
                    name: "Resource name",
                    value: "{{resource_name}}",
                    sample: "Jane",
                },
                {
                    name: "Salon name",
                    value: "{{salon_name}}",
                    sample: "Salon AB",
                },
                {
                    name: "Treatment name",
                    value: "{{treatment_name}}",
                    sample: "LUXE BROWS",
                },
                {
                    name: "Booking date",
                    value: "{{booking_date}}",
                    sample: "2021-11-30",
                },
                {
                    name: "Booking time",
                    value: "{{booking_time}}",
                    sample: "13:37",
                },
                {
                    name: "Booking number",
                    value: "{{booking_number}}",
                    sample: "2448",
                },
                {
                    name: "Cancel booking url",
                    value: "{{cancel_booking_url}}",
                    sample: "https://google.se",
                },
            ],
        },
    },
    {
        emailType: "3",
        setting: {
            hint: "Hint text.",
            mergeTags: [
                {
                    name: "First Name",
                    value: "{{first_name}}",
                    sample: "John",
                },
                {
                    name: "Resource name",
                    value: "{{resource_name}}",
                    sample: "Jane",
                },
                {
                    name: "Salon name",
                    value: "{{salon_name}}",
                    sample: "Salon AB",
                },
                {
                    name: "Treatment name",
                    value: "{{treatment_name}}",
                    sample: "LUXE BROWS",
                },
                {
                    name: "Booking date",
                    value: "{{booking_date}}",
                    sample: "2021-11-30",
                },
                {
                    name: "Booking time",
                    value: "{{booking_time}}",
                    sample: "13:37",
                },
                {
                    name: "Booking number",
                    value: "{{booking_number}}",
                    sample: "2448",
                },
            ],
        },
    },
    {
        emailType: "4",
        setting: {
            hint: "Hint text.",
            mergeTags: [
                {
                    name: "First Name",
                    value: "{{first_name}}",
                    sample: "John",
                },
                {
                    name: "Resource name",
                    value: "{{resource_name}}",
                    sample: "Jane",
                },
                {
                    name: "Salon name",
                    value: "{{salon_name}}",
                    sample: "Salon AB",
                },
                {
                    name: "Treatment name",
                    value: "{{treatment_name}}",
                    sample: "LUXE BROWS",
                },
                {
                    name: "Booking date",
                    value: "{{booking_date}}",
                    sample: "2021-11-30",
                },
                {
                    name: "Booking time",
                    value: "{{booking_time}}",
                    sample: "13:37",
                },
                {
                    name: "Booking number",
                    value: "{{booking_number}}",
                    sample: "2448",
                },
            ],
        },
    },
];
